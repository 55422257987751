import React, { useEffect } from "react";

const CookiePolicy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "termly-jssdk");
    script.setAttribute("src", "https://app.termly.io/embed-policy.min.js");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      name="termly-embed"
      data-id="4528fb08-b638-4f4d-9e4f-d899261ed495"
      data-type="iframe"
    ></div>
  );
};

export default CookiePolicy;
