import React from "react";

const ComplianceNav = () => {
  const complianceLinks = [
    { name: "Privacy Policy", url: "/compliance/privacy-policy" },
    { name: "Terms of Service", url: "/compliance/terms-and-conditions" },
    { name: "Cookie Policy", url: "/compliance/cookie-policy" },
  ];
  return (
    <ul className="table mx-auto text-black">
      {complianceLinks.map((link, index) => {
        return (
          <li key={index} className="mb-4 float-left px-6 font-inter font-bold">
            <a className="underline copy" href={link.url}>
              {link.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default ComplianceNav;
